// import { everyMonth, onceWeek, oneTime, others, twiceMonth, twiceWeeks } from "../constants/pricings";
import PricingCard from "./pricingCard";
// import { useEffect, useState } from "react";
// import axios from "axios";

export function RenderOneTime({ pricing }) {
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs:grid-cols-1 gap-x-10 gap-y-5">
      {pricing.map((tarrif, index) => (
        <div
          key={index}
          className="bg-white mt-5 flex justify-center items-center pt-10 pb-6 px-5 rounded-xl text-center"
        >
          <PricingCard
            image={tarrif.priceImage}
            price={tarrif.priceAmount}
            type={tarrif.priceName}
            desc1={tarrif.priceItems}
          />
        </div>
      ))}
    </div>
  );
}

export function RenderOnceWeek({ pricing }) {
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs-grid-cols-1 gap-x-10 gap-y-5">
      {pricing.map((tarrif, index) =>  {
        return (
          <div
            key={index}
            className="bg-white mt-20 flex justify-center items-center py-10 px-5 rounded-xl text-center font-helvetica-neue"
          >
            <PricingCard
              image={tarrif.priceImage}
              price={tarrif.priceAmount}
              type={tarrif.priceName}
              desc1={tarrif.priceItems}
            />
          </div>
        );
      })}
    </div>
  );
}

export  function RenderTwiceMonth({ pricing }) {
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs-grid-cols-1 gap-x-10 gap-y-5">
      {pricing.map((tarrif, index) => {
        return (
          <div
            key={index}
            className="bg-white mt-20 flex justify-center items-center py-10 px-5 rounded-xl text-center font-helvetica-neue"
          >
            <PricingCard
              image={tarrif.priceImage}
              price={tarrif.priceAmount}
              type={tarrif.priceName}
              desc1={tarrif.priceItems}
            />
          </div>
        );
      })}
    </div>
  );
}

export  function RenderEveryMonth({ pricing }) {
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs-grid-cols-1 gap-x-10 gap-y-5">
      {pricing.map((tarrif, index) => {
        return (
          <div
            key={index}
            className="bg-white mt-20 flex justify-center items-center py-10 px-5 rounded-xl text-center font-helvetica-neue"
          >
            <PricingCard
              image={tarrif.priceImage}
              price={tarrif.priceAmount}
              type={tarrif.priceName}
              desc1={tarrif.priceItems}
              // desc2={tarrif.desc2}
              // desc3={tarrif.desc3}
              // desc4={tarrif.desc4}
              // desc5={tarrif.desc5}
            />
          </div>
        );
      })}
    </div>
  );
}

export  function RenderOthers({ pricing }) {
  return (
    <div className="grid xl:grid-cols-4 md:grid-cols-2 xs-grid-cols-1 gap-x-10 gap-y-5">
      {pricing.map((tarrif, index) => {
        return (
          <div
            key={index}
            className="bg-white mt-20 flex justify-center items-center py-10 px-5 rounded-xl text-center font-helvetica-neue"
          >
            <PricingCard
              image={tarrif.priceImage}
              price={tarrif.priceAmount}
              type={tarrif.priceName}
              desc1={tarrif.priceItems}
            />
          </div>
        );
      })}
    </div>
  );
}
