import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { useState, useEffect } from "react";
import axios from "axios";
import TestimonyCard from "../testimonyCard";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonies = async () => {
      try {
        const response = await axios.get("https://klean-up-server-hz1y.onrender.com/v1/api/testimonies");
        if (response.data.success) {
          setTestimonials(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching testimonies:", error);
      }
    };

    fetchTestimonies();

    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="py-10 font-helvetica-neue xl:px-52 lg:px-32 md:px-16 sm:px-8 xs:px-4">
      <div className="flex my-20 justify-between">
        <div>
          <p className="font-semibold text-2xl md:text-3xl mb-4" data-aos='fade-up'>Testimonials</p>
          <p className="text-sm md:text-md inline-block max-w-[400px]" data-aos='fade-up' data-aos-delay='300'>
            We believe that the true measure of our success lies in the
            experiences and voices of our valued customers.
          </p>
        </div>
        <div className="flex gap-x-10">
          <button
            onClick={prevSlide}
            className="border border-black rounded-full w-[40px] h-[40px] flex justify-center items-center"
          >
            <GoChevronLeft size={30} />
          </button>
          <button
            onClick={nextSlide}
            className="border border-black rounded-full w-[40px] h-[40px] flex justify-center items-center"
          >
            <GoChevronRight size={30} />
          </button>
        </div>
      </div>
      <div data-aos='fade-up'
        className={`flex  sm:w-[450px] xs:w-[350px] gap-x-6 transition-transform ease-in-out duration-500 transform translate-x-${
          currentIndex * 100
        }%`}
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {testimonials.map((t, index) => (
          <div key={t._id} className="flex-none ">
            <TestimonyCard
              icon={t.image}
              img={t.image}
              desc={t.content}
              name={t.name}
              title={t.workTitle}
              rating={t.rating}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
