/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import Navigation from "../components/nav";
import ServiceCard from "../components/serviceCard";
import Regular from "../assests/images/regular.png";
import ScheduleComp from "../components/home-ui-components/scheduleComp";
import RenderSchedule from "../components/renderSchedule";
import Mop from "../assests/images/mop.png";
import Dish from "../assests/images/dish-soap.png";
import Housekeep from "../assests/images/housekeeping.png";
import { useEffect, useRef, useState } from "react";
import Footer from "../components/home-ui-components/footer";
import Contact from "../components/schedule-ui-components/contact";
import ValidationBehaviorView from "../components/schedule-ui-components/datePicker";
import { FaTelegram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { PiTelegramLogoLight } from "react-icons/pi";
import { servicesArr } from "../constants/service";
import UserProfile from "../assests/images/user-profile.png";
import Office from "../assests/images/office-building.png";
import { useDispatch, useSelector } from "react-redux";
import { ModalRequestDetail } from "../components/modalView/CleanerModal";
import PaymentModal from "../components/customer-component/customer-dashboard-components/dashboard-payment/paymentModal";
import LoadingModal from "../components/modalView/loadingModal";
import SuccessModal from "../components/modalView/successModal";
import gsap from "gsap";
import axios from "axios";
import ShowModal from "../components/muiModal/modal";
import { motion } from "framer-motion";

export default function Schedule() {

   const [period, setPeriod] = useState("oneTime");
  const animationContainerRef = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  const [profile, setProfile] = useState("individual");
  const bookSteps = useSelector((state) => state.toggle.booking);
  const [category, setCategory] = useState(1);
  const dispatch = useDispatch();
  const [selectedService, setSelectedService] = useState("Restroom Standard Cleaning");
  const [productId, setProductId] = useState(null);
  const [services, setServices] = useState([]);
  const [userContacts, setUsersContacts] = useState({});
  const [desireDate, setDesireDate] = useState(null);
  const [desireTime, setDesireTime] = useState(null);
  const [productCounts, setProductCounts] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleSelectChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    setPeriod(selectedValue);
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectCategory = (index, serviceName, _id) => {
    setSelectedService(serviceName);
    setCategory(index);
  };

  const getProductCount = (productId, count) => {
    setProductCounts((prevCounts) => ({
      ...prevCounts,
      [productId]: count,
    }));
  };

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (hasMounted && animationContainerRef.current) {
      gsap.from(".animation-container > div", {
        duration: 1,
        opacity: 0,
        x: 110,
        stagger: 0.9,
        ease: "power3.out",
      });
    }
  }, [hasMounted, category]);

  useEffect(() => {
    fetchServiceCategories();
  }, []);

  useEffect(() => {
    console.log("Selected Services:", selectedServices);
  }, [selectedServices]);

  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(
        "https://klean-up-server-hz1y.onrender.com/v1/api/getAllServiceCategories"
      );
      setServices(response.data.reverse());
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };

  // Constructing the services array with serviceId and serviceQuantity
  const servicesArray = selectedServices.map((serviceId) => ({
    serviceId: serviceId,
    serviceQuantity: productCounts[serviceId] || 1,
  }));

  const orderDetails = {
    orderType: profile,
    desireDate: desireDate,
    desireTime: desireTime ,
    orderRange: period ,
    services: servicesArray,
    postCode: userContacts.postCode,
    streetName: userContacts.streetName,
    paymentMethod: paymentMethod,
    city: userContacts.city,
    houseNumber: userContacts.houseNumber,
    building: userContacts.building,
    entranceNumber: userContacts.entranceNumber,
    customerPhoneNumber: userContacts.phoneNumber,
    additonalInformation: userContacts.additionalInformation,
  };

  console.log("Order Details:", orderDetails);

  return (
    <>
      <div className=" font-helvetica-neue overflow-hidden">
        <div className="schedule-container flex md:flex-row xs:flex-col py-10 text-white justify-between 2xl:px-48 lg:px-20 md:px-10 sm:px-6 xs:px-4 ">
          <div className="flex flex-col justify-center mb-10 mr-5">
            <p className="text-3xl font-semibold mb-5">Regular Cleaning</p>
            <p className="text-sm font-medium max-w-[500px]">
              No long-term contracts – just quality cleaning, on your terms.
            </p>
          </div>

          <div className="max-w-[350px] max-sm:mx-auto">
            <ServiceCard
              image={Regular}
              type="Regular Cleaning"
              showArrow={false}
              width={300}
            />
          </div>
        </div>

        <div className="bg-gray-100">
          <h1 class="text-xl font-semibold pl-4 md:pl-6 pt-4">Choose an option:</h1>

          <div className="relative z-[10] flex justify-center  px-5">
            <div className="custom-scrollbar w-full flex  overflow-auto scroll-smooth">
              <div className="flex mt-10 gap-5 mx-auto pb-10">
                {services.map((service, index) => (
                  <div
                    key={index}
                    className={` ${
                      category === index
                        ? "border-4 border-[#3DA5EC] rounded-[27px]"
                        : ""
                    }`}
                    onClick={() =>
                      handleSelectCategory(index, service.name, service._id)
                    }
                  >
                    <ServiceCard
                      image={service.image}
                      type={service.name}
                      price={service.price}
                      range={service.range}
                      width={290}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <section className="bg-white">
          <div className="flex justify-center mb-20 mt-10">
            <div
              className="flex gap-5 mt-10 py-3 px-6 rounded-full justify-center items-center w-fit cursor-pointer max-sm:hidden"
              style={{ backgroundColor: "#F5F9FD", color: "#BDBDBD" }}
            >
              <div
                onClick={() => {
                  setPeriod("oneTime");
                }}
                className="p-[4px] rounded-full"
                style={{
                  color: period === "oneTime" && "#000",
                  backgroundColor: period === "oneTime" ? "#fff" : null,
                  padding: period === "oneTime" && "4px",
                }}
              >
                <p>One Time</p>
              </div>
              <div
                onClick={() => {
                  setPeriod("OnceAweek");
                }}
                className="p-[4px] rounded-full"
                style={{
                  color: period === "OnceAweek" && "#000",
                  backgroundColor: period === "OnceAweek" ? "#fff" : null,
                  padding: period === "OnceAweek" && "4px",
                }}
              >
                <p>Once a week</p>
              </div>
              <div
                onClick={() => {
                  setPeriod("TwiceAmonth");
                }}
                className="p-[4px] rounded-full"
                style={{
                  color: period === "TwiceAmonth" && "#000",
                  backgroundColor: period === "TwiceAmonth" ? "#fff" : null,
                  padding: period === "TwiceAmonth" && "4px",
                }}
              >
                <p>Twice a month</p>
              </div>
              <div
                onClick={() => {
                  setPeriod("EveryMonth");
                }}
                className="p-[4px] rounded-full"
                style={{
                  color: period === "EveryMonth" && "#000",
                  backgroundColor: period === "EveryMonth" ? "#fff" : null,
                  padding: period === "EveryMonth" && "4px",
                }}
              >
                <p>Every month</p>
              </div>
              <div
                onClick={() => {
                  setPeriod("other");
                }}
                className="p-[4px] rounded-full"
                style={{
                  color: period === "other" && "#000",
                  backgroundColor: period === "other" ? "#fff" : null,
                  padding: period === "other" && "4px",
                }}
              >
                <p>Others</p>
              </div>
            </div>
            <div className="relative sm:hidden pt-10">
              <select
                value={period}
                onChange={handleSelectChange}
                className="h-10 px-2 border border-gray-300 rounded-lg focus:outline-none"
              >
                <option value={1}>One Time</option>
                <option value={2}>Once a week</option>
                <option value={3}>Twice a month</option>
                <option value={4}>Every month</option>
                <option value={5}>Others</option>
              </select>
            </div>
          </div>
          <div
            className="pr-7 max-md:pr-0 animation-container"
            ref={animationContainerRef}
          >
            <RenderSchedule
              getSelectedProducts={setSelectedServices}
              getProductCount={getProductCount}
              selectedService={selectedService}
            />
          </div>

          <div className="bg-sky-50 py-12 w-full">
            <div className="flex flex-col items-center justify-center text-center mb-16">
              <p className="text-lg md:text-2xl font-medium mb-3">
                Pick a suitable date and time for your cleaning
              </p>
              <p className="text-md inline-block max-w-[430px]">
                Your cleaner comes fully equipped with all the necessary
                cleaning tools and solutions.
              </p>
            </div>
            <div>
              <ValidationBehaviorView
                onSelectDate={setDesireDate}
                onSelectTime={setDesireTime}
              />
            </div>
          </div>
        </section>
        <div>
          <Contact
            toggleModal={() => handleModalState()}
            contacts={setUsersContacts}
            paymentMethod={setPaymentMethod}
          />
        </div>
      </div>
      {
        <ShowModal
          element={
            <ModalRequestDetail {...orderDetails} service={selectedService} />
          }
          isOpen={isOpen}
          onClose={handleModalState}
          width={600}
        />
      }
    </>
  );
}

