import { IoIosArrowRoundForward } from "react-icons/io";

export default function BlogCard({ image, aim, text }) {
  return (
    <div>
      <div className="w-full h-48 md:h-64 overflow-hidden">
        <img src={image} alt="" className="w-full h-full object-cover" />
      </div>
      <div className="px-3 py-2 md:py-6 text-left">
        <p className="font-semibold text-lg md:text-xl inline-block max-w-[220px]">
          {aim}
        </p>
        <p className="text-sm max-w-[270px] mt-3" style={{ color: "#434343" }}>
          {text}
        </p>

        <div className="flex items-center space-x-1 mt-3 cursor-pointer group hover:space-x-2 transition-all duration-150 ease-in-out">
          <p className="font-medium text-sm text-gray-900 group-hover:text-blue-500 transition-colors duration-150">
            Read More
          </p>
          <IoIosArrowRoundForward
            size={24}
            className="text-gray-900 group-hover:text-blue-500 transition-colors duration-150"
          />
        </div>
      </div>
    </div>
  );
}
