import Navigation from "../components/nav";
import PriceTag from "../assests/images/price-tag.png";
import Dollar from "../assests/images/dollar.png";
import FAQs from "../components/home-ui-components/faqs";
import Partners from "../components/home-ui-components/partners";
import Footer from "../components/home-ui-components/footer";
import PricePage from "../components/pricecards";

// import PriceLog from "../components/price-ui-compomnents/price-log";
// import MiniDollar from "../assests/images/mini-dollar.png";
// import MiniTag from "../assests/images/mini-tag.png";

export default function Price() {
  return (
    <div className="font-helvetica-neue  overflow-hidden">
      <div className="bg-white">
        <Navigation />
      </div>
      <div className="bg-black py-24 md:py-6 flex justify-center md:justify-between items-center">
        <img
          src={Dollar}
          alt=""
          className="max-lg:w-[170px] max-md:w-[130px] hidden md:block"
        />
        <div className="text-white text-center">
          <p className="font-medium text-3xl mb-5 max-md:text-xl">
            Pricing plans
          </p>
          <p className="inline-block max-w-[420px] max-md:text-sm">
            We believe that the true measure of our success lies in the
            experiences and voices of our valued customers.
          </p>
        </div>
        <img
          src={PriceTag}
          alt=""
          className="max-lg:w-[170px] max-md:w-[130px] hidden md:block"
        />
      </div>
      {/* <div className="bg-white flex justify-center py-20" style={{ backgroundColor: "#e2e2e2" }}>
        <PriceLog />
      </div> */}

      <div class="py-20 px-4 md:px-24 lg:px-20 xl:px-40">
        <PricePage />
      </div>

      <section>
        <div className="bg-white py-20 sm:px-8 md:px-24 lg:px-20 xl:px-40">
          <div className="flex justify-between pb-10">
            <p className="text-2xl font-medium">FAQS</p>
            <p className="text-gray-500">See all</p>
          </div>
          <FAQs />
        </div>
        <div className="mt-10 bg-white pt-10">
          <Partners />
        </div>
        <div className="mt-20">
          <Footer />
        </div>
      </section>
    </div>
  );
}
