import React, { useState, useEffect } from "react";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import axios from "axios";
import Preloader from '../preloader/preloader'; 

const FAQs = () => {
  const [faqs, setFaqs] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchFaqs() {
      try {
        const response = await axios.get(`${apiUrl}/getFaqs`);
        if (response.data.faqs) {
          setFaqs(response.data.faqs);
        }
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch FAQs:", error);
        setError('Failed to fetch FAQs');
        setLoading(false);
      }
    }
    fetchFaqs();
  }, []);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className=" ">
      {faqs.map((faq, index) => (
        <div
          key={index}
          className="mb-4 p-4 rounded-md cursor-pointer"
          style={{ backgroundColor: "#eef5f7" }}
        >
          <div
            className="flex justify-between items-center"
            onClick={() => handleToggle(index)}
          >
            <p className="font-normal text-gray-600">{faq.title}</p>
            {openIndex === index ? (
              <HiOutlineChevronUp />
            ) : (
              <HiOutlineChevronDown />
            )}
          </div>
          {openIndex === index && <p className="mt-2">{faq.content}</p>}
        </div>
      ))}
    </div>
  );
};

export default FAQs;
