import { HiOutlineArrowUpRight } from "react-icons/hi2";

const defaultProps = {
  showArrow: true,
};

export default function ServiceCard({
  type,
  image,
  showArrow,
  price,
  range,
  onClick,
}) {
  showArrow = showArrow ?? defaultProps.showArrow;

  return (
    <div
      className="relative flex flex-col items-start justify-between px-6 py-10 rounded-3xl h-[420px] service-card duration-150 overflow-hidden cursor-pointer"
      onClick={onClick}
    >
      {/* Background Image */}
      <img
        src={image}
        alt=""
        className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 ease-in-out scale-100 service-image"
        // style={{ filter: "brightness(60%)" }}
      />
      <div className="absolute inset-0 w-full h-full black-overlay"></div>

      {/* Arrow Icon */}
      <div className="relative z-10 self-end">
        {showArrow && (
          <div className="self-end arrow border border-white h-14 w-14 rounded-full items-center justify-center flex">
            <HiOutlineArrowUpRight className="service-icon" />
          </div>
        )}
      </div>

      {/* Text Content */}
      <div className="relative z-10 self-start text-white">
        <p className="font-medium text-xl">{type}</p>
        <div className="bg-white bg-opacity-40 rounded-full px-3 py-1 mt-2 inline-block">
          <p>
            €{price} / {range}
          </p>
        </div>
      </div>
    </div>
  );
}
