import { useEffect, useState } from "react";
import axios from "axios";

export default function PricingCard({
  image,
  type,
  price,
  desc1,
  
}) 

{
  const [, setPricing] = useState([]);

  useEffect(() => {
    fetchServiceCategories();
  }, []);

  const fetchServiceCategories = async () => {
    try {
      const response = await axios.get(
        "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings"
      );
      
      setPricing(response.data.reverse());
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };
  return (
    <div>
      <div className="">
        <div className="flex flex-col items-center">
          <img src={image} alt="" className="w-[80px] h-[80px]" />
          <p className="font-medium mt-4 mb-2">{type}</p>
          <p className="inline-block text-3xl flex items-center font-bold">
            <span className="text-[24px] font-normal mr-1">€</span>
            <span>{price}</span>
            <p
              className="inline-block text-base font-normal"
              style={{ color: "#828282" }}
            >
              <span className="ml-1">/Hour</span>
            </p>
          </p>
        </div>
        <div className="test-base font-normal">
          {/* <p className="my-2">{desc1}</p> */}
          {desc1.map((item, index) => (
            <p key={index} className="my-2">
              {item.name}: ${item.price}
            </p>
          ))}
        </div>
        <button className="py-2.5 px-6 bg-primary-sky mt-6 text-sm font-medium text-white rounded-md">
          Learn more
        </button>
      </div>
    </div>
  );
}
