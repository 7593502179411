import React from 'react'
import Index from '../../components/customer-component/customer-dashboard-components/dashboard'

export default function CustomerHome() {
  return (
    <div>
        <Index />
    </div>
  )
}
