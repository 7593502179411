import { useEffect, useRef, useState } from "react";
import gsap from "gsap";

import {
  RenderEveryMonth,
  RenderOnceWeek,
  RenderOthers,
  RenderTwiceMonth,
  RenderOneTime
} from "../renderPrices";
// import { RenderOneTime, RenderOnceWeek, RenderEveryMonth, RenderTwiceMonth, RenderOthers } from "../renderPrices";
import axios from "axios";

export default function ScheduleComp() {
  const animationContainerRef = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);

  const [period, setPeriod] = useState(1);
  const [oneTimeData, setOneTimeData] = useState([]);
  const [onceWeekData, setOnceWeekData] = useState([]);
  const [twiceMonthData, setTwiceMonthData] = useState([]);
  const [everyMonthData, setEveryMonthData] = useState([]);
  const [othersData, setOthersData] = useState([]);

  useEffect(() => {
    // Fetch data for all tabs once on mount
    fetchAllPricings();
  }, []);

  const fetchAllPricings = async () => {
    try {
      const [oneTime, onceWeek, twiceMonth, everyMonth, others] =
        await Promise.all([
          axios.get(
            "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/oneTime"
          ),
          axios.get(
            "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/OnceAweek"
          ),
          axios.get(
            "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/TwiceAmonth"
          ),
          axios.get(
            "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/EveryMonth"
          ),
          axios.get(
            "https://klean-up-server-hz1y.onrender.com/v1/api/getAllPricings/others"
          ),
        ]);

      setOneTimeData(oneTime.data.reverse());
      setOnceWeekData(onceWeek.data.reverse());
      setTwiceMonthData(twiceMonth.data.reverse());
      setEveryMonthData(everyMonth.data.reverse());
      setOthersData(others.data.reverse());
    } catch (error) {
      console.error("Error fetching service categories:", error);
    }
  };

  // const handleSelectChange = (event) => {
  //   const selectedValue = parseInt(event.target.value, 10);
  //   setPeriod(selectedValue);
  // };

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (hasMounted && animationContainerRef.current) {
      gsap.from(".animation-container > div", {
        duration: 0.5,
        opacity: 0,
        x: 100,
        stagger: 0.9,
        ease: "power3.out",
      });
    }
  }, [hasMounted, period]);

  return (
    <div className="flex flex-col items-center">
      <div
        className="flex gap-5 mt-10 py-3 px-6 rounded-full justify-center items-center w-fit cursor-pointer max-sm:hidden"
        style={{ backgroundColor: "#F5F9FD", color: "#BDBDBD" }}
      >
        <div
          onClick={() => {
            setPeriod(1);
          }}
          className="p-[4px] rounded-full"
          style={{
            color: period === 1 && "#000",
            backgroundColor: period === 1 ? "#fff" : null,
            padding: period === 1 && "4px",
          }}
        >
          <p>One Time</p>
        </div>
        <div
          onClick={() => {
            setPeriod(2);
          }}
          className="p-[4px] rounded-full"
          style={{
            color: period === 2 && "#000",
            backgroundColor: period === 2 ? "#fff" : null,
            padding: period === 2 && "4px",
          }}
        >
          <p>Once a week</p>
        </div>
        <div
          onClick={() => {
            setPeriod(3);
          }}
          className="p-[4px] rounded-full"
          style={{
            color: period === 3 && "#000",
            backgroundColor: period === 3 ? "#fff" : null,
            padding: period === 3 && "4px",
          }}
        >
          <p>Twice a month</p>
        </div>
        <div
          onClick={() => {
            setPeriod(4);
          }}
          className="p-[4px] rounded-full"
          style={{
            color: period === 4 && "#000",
            backgroundColor: period === 4 ? "#fff" : null,
            padding: period === 4 && "4px",
          }}
        >
          <p>Every month</p>
        </div>
        <div
          onClick={() => {
            setPeriod(5);
          }}
          className="p-[4px] rounded-full"
          style={{
            color: period === 5 && "#000",
            backgroundColor: period === 5 ? "#fff" : null,
            padding: period === 5 && "4px",
          }}
        >
          <p>Others</p>
        </div>
        {/* mini */}
      </div>
      {/* <div className="relative sm:hidden mt-10">
          <select value={period} onChange={handleSelectChange} className="h-10 px-5 rounded-full focus:outline-none" >
            <option value={1}>One Time</option>
            <option value={2}>Once a week</option>
            <option value={3}>Twice a month</option>
            <option value={4}>Every month</option>
            <option value={5}>Others</option>
          </select>
        </div> */}

      <div className="animation-container mt-16" ref={animationContainerRef}>
        {period === 1 && <RenderOneTime pricing={oneTimeData} />}
        {period === 2 && <RenderOnceWeek pricing={onceWeekData} />}
        {period === 3 && <RenderTwiceMonth pricing={twiceMonthData} />}
        {period === 4 && <RenderEveryMonth pricing={everyMonthData} />}
        {period === 5 && <RenderOthers pricing={othersData} />}
      </div>
    </div>
  );
}
