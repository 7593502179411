import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chat from "../../assests/images/chat.png";

import AOS from "aos";
import "aos/dist/aos.css";

export default function Contact() {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting form
    try {
      const response = await axios.post(
        "https://klean-up-server-hz1y.onrender.com/v1/api/get-in-touch",
        formData
      );
      if (response.data.success) {
        toast.success("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      toast.error("Failed to send message. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grid gap-10 grid-cols-1 md:grid-cols-2 px-4 py-20 items-center">
      <div className='w-full flex justify-start md:justify-end'>
        <div data-aos='fade-right' className="w-[400px] md:w-[450px] lg:w-[550px] pt-10 md:pl-10 flex flex-col rounded-xl lg:bg-gray-100">
        <div className="text-left">
          <p className="text-lg font-semibold">GET IN TOUCH</p>
          <p className="text-3xl font-bold my-4 inline-block">
            We are here to help you with <br className="hidden md:block" /> your cleaning
          </p>
          <p className="inline-block w-[95%] md:w-[88%]">
            Whether you're moving in or out, our elite cleaning service prepares
            your home with a meticulous clean that meets leasing standards.
          </p>
        </div>
        <div className="ml-auto mt-16 max-lg:hidden">
          <img src={Chat} alt="chat" />
        </div>
      </div>
      </div>

      <div className="w-full" data-aos='fade-left' data-aos-delay='300'>
        <form
          className="flex flex-col w-[98%] md:w-[80%] lg:w-[65%] gap-5 border-black"
          onSubmit={handleSubmit}
        >
          <input
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            className="border border-gray-200 p-3 bg-gray-100 rounded-lg"
          />
          <input
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="border border-gray-200 p-3 bg-gray-100 rounded-lg"
          />
          <input
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            className="border border-gray-200 p-3 bg-gray-100 rounded-lg"
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            className="border border-gray-200 p-3 bg-gray-100 rounded-lg"
            rows={5}
          ></textarea>
          <button
            type="submit"
            className="bg-primary-sky text-white py-3 font-medium rounded-lg text-md"
          >
            {loading ? "...Submitting..." : "Submit now"}{" "}
            {/* Display loader if loading */}
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
