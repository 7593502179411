import { pricingArr } from "../constants/pricings";

const PricePage = () => {
  return (
    <>
      <div className="grid gap-4 grid-cols-2 md:grid-cols-4">
        {pricingArr.map((pricing, index) => (
          <div
            key={index}
            className={`px-5 pt-6 pb-3 rounded-lg ${pricing.color}`}
          >
            <div>
              <h4 className="text-xl font-semibold">{pricing.type}</h4>
              <p className="font-medium mt-1 text-sm">{pricing.remark}</p>
            </div>

            <div className="flex items-center space-x-1 mb-3 mt-6">
              <p className="text-xl font-medium">&euro;</p>
              <h3 className="text-4xl font-semibold">{pricing.price}</h3>

              <div className="self-end">
                <p className="text-md font-semibold mb-1">/hour</p>
              </div>
            </div>

            <button className="bg-slate-950 w-full rounded-lg text-white px-2 py-2.5 text-sm font-medium">
              Pay now
            </button>
          </div>
        ))}
      </div>

      <div className="mt-10 overflow-x-auto rounded-lg">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 border-b border-gray-200 text-left text-gray-600 font-bold uppercase">
                Plan
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-gray-600 font-bold uppercase">
                Basic Cleaning
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-gray-600 font-bold uppercase">
                Deep Cleaning
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-gray-600 font-bold uppercase">
                Advanced Cleaning
              </th>
              <th className="px-6 py-3 border-b border-gray-200 text-left text-gray-600 font-bold uppercase">
                Office Cleaning
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {/* Example Features Rows */}
            <tr className="even:bg-gray-50 hover:bg-gray-100">
              <td className="px-6 py-4 border-b border-gray-200">Dusting</td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                —
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
            </tr>
            <tr className="even:bg-gray-50 hover:bg-gray-100">
              <td className="px-6 py-4 border-b border-gray-200">Mopping</td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                —
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
            </tr>
            <tr className="even:bg-gray-50 hover:bg-gray-100">
              <td className="px-6 py-4 border-b border-gray-200">Vacuuming</td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                —
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
            </tr>
            <tr className="even:bg-gray-50 hover:bg-gray-100">
              <td className="px-6 py-4 border-b border-gray-200">
                Window Cleaning
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                —
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                —
              </td>
            </tr>
            <tr className="even:bg-gray-50 hover:bg-gray-100">
              <td className="px-6 py-4 border-b border-gray-200">
                Bathroom Sanitization
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                —
              </td>
              <td className="px-6 py-4 border-b border-gray-200 text-center">
                ✓
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PricePage;
