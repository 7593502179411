import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../components/nav";
// import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsArrowLeft } from "react-icons/bs";

export default function CleanerRegForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",  // New phone number field
    resumeFile: null,
    idProof: null,    // New ID proof image field
    workAuth: null    // New work authorization image field
  });
  const [fileNames, setFileNames] = useState({
    resumeFile: "Drag & drop or click to choose a file",
    idProof: "Drag & drop or click to choose a file",
    workAuth: "Drag & drop or click to choose a file",
  });

  // const handleChange = (e) => {
  //   const { name, value, files } = e.target;
  //   if (files) {
  //     setFormData({ ...formData, [name]: files[0] }); // Update respective file field
  //   } else {
  //     setFormData({ ...formData, [name]: value });
  //   }
  // };

  const handleChange = (e) => {
    const { name, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
      setFileNames({ ...fileNames, [name]: files[0].name });
    }
  };

  const goBack = () => {
    navigate('/membership');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const payload = new FormData();
    payload.append("fullname", formData.fullname);
    payload.append("email", formData.email);
    payload.append("phoneNumber", formData.phoneNumber);
  
    // Append each image with the same key "image"
    if (formData.resumeFile) {
      payload.append("images", formData.resumeFile);
    }
    if (formData.idProof) {
      payload.append("images", formData.idProof);
    }
    if (formData.workAuth) {
      payload.append("images", formData.workAuth);
    }
  
    try {
      // const response = await axios.post(
      //   "https://klean-up-server-hz1y.onrender.com/v1/api/cleaners",
      //   payload,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      toast.success("Application successful!");
      setFormData({
        fullname: "",
        email: "",
        phoneNumber: "",
        resumeFile: null,
        idProof: null,
        workAuth: null
      });
    } catch (error) {
      console.error("Application failed:", error);
      toast.error(
        error.response?.data?.error ||
        "Application failed. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <div>
        <Navigation />
      </div>
      <div class="apply-bg">
        <div className="text-center">
          <p className="text-white text-2xl md:text-4xl font-semibold">
            Cleaner Application
          </p>
          <p class="font-medium text-md text-white font-medium mt-2">
            Committed to Cleaning, Every Step of the Way
          </p>
        </div>
      </div>
      <div className="flex justify-center bg-gray-50 pt-16">
        <div className="w-[450px] lg:w-[550px]">
          <ToastContainer />
          <div className="mb-[100px]">
            <form onSubmit={handleSubmit}>
              <div className="bg-white w-full flex flex-col p-5 rounded-lg mx-2">
                <div
                  className="bg-blue-50 text-blue-600 w-fit p-2 rounded-lg items-center flex space-x-2 cursor-pointer mb-3"
                  onClick={goBack}
                >
                  <BsArrowLeft />
                  <p className="text-sm font-medium">Back</p>
                </div>

                <div class="space-y-4">
                  <div>
                    <label className="mb-1 text-sm text-gray-700 block font-medium">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="fullname"
                      value={formData.fullname}
                      onChange={handleChange}
                      placeholder="Enter your full name"
                      className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 block w-full"
                    />
                  </div>

                  <div>
                    <label className="mb-1 text-sm text-gray-700 block font-medium">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email address"
                      className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 block w-full"
                    />
                  </div>

                  <div>
                    <label className="mb-1 text-sm text-gray-700 block font-medium">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      placeholder="Enter your phone number"
                      className="border border-[#CECECE] outline-none rounded-md placeholder:text-[#a1a0a0] bg-[#FAFAFA] py-2 px-5 block w-full"
                    />
                  </div>

                  <div>
                    <label className="mb-1 text-sm text-gray-700 block font-medium">
                      Resume/CV (Lebenslauf)
                    </label>
                    <div className="border-2 border-dashed border-gray-300 rounded-2xl p-5 flex flex-col items-center">
                      <input
                        type="file"
                        name="resumeFile"
                        onChange={handleChange}
                        className="hidden"
                        id="resumeFile"
                      />
                      <label
                        htmlFor="resumeFile"
                        className="text-center cursor-pointer text-gray-600"
                      >
                        <div className="text-2xl">+</div>
                        <div className="text-sm mt-2">
                          {fileNames.resumeFile}
                        </div>
                        <div className="text-xs text-gray-400">
                          Max file size: 10 MB
                        </div>
                      </label>
                    </div>
                  </div>

                  <div>
                    <label className="mb-1 text-sm text-gray-700 block font-medium">
                      Identification Proof
                    </label>
                    <div className="border-2 border-dashed border-gray-300 rounded-2xl p-5 flex flex-col items-center">
                      <input
                        type="file"
                        name="idProof"
                        onChange={handleChange}
                        className="hidden"
                        id="idProof"
                      />
                      <label
                        htmlFor="idProof"
                        className="text-center cursor-pointer text-gray-600"
                      >
                        <div className="text-2xl">+</div>
                        <div className="text-sm mt-2">{fileNames.idProof}</div>
                        <div className="text-xs text-gray-400">
                          Max file size: 10 MB
                        </div>
                      </label>
                    </div>
                  </div>

                  <div>
                    <label className="mb-1 text-sm text-gray-700 block font-medium">
                      Work Authorization
                    </label>
                    <div className="border-2 border-dashed border-gray-300 rounded-2xl p-5 flex flex-col items-center">
                      <input
                        type="file"
                        name="workAuth"
                        onChange={handleChange}
                        className="hidden"
                        id="workAuth"
                      />
                      <label
                        htmlFor="workAuth"
                        className="text-center cursor-pointer text-gray-600"
                      >
                        <div className="text-2xl">+</div>
                        <div className="text-sm mt-2">{fileNames.workAuth}</div>
                        <div className="text-xs text-gray-400">
                          Max file size: 10 MB
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="bg-primary-sky text-white py-2.5 text-sm font-medium mt-8 rounded-md text-center"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}