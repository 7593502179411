import React from "react";
import { useDispatch } from "react-redux";

function CardDetails({ card }) {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-2">
        <label className="text-[14px]">Card number</label>
        <input
          className="px-3 py-2.5 text-sm border border-[#CECECE] bg-[#FAFAFA] rounded-md outline-none text-[#353535]"
          value={card ? card.cardNumber : ''}
          // readOnly
        />
      </div>
      <div className="flex flex-col gap-2">
        <label className="text-[14px]">Expiry Date</label>
        <input
          className="px-3 py-2.5 text-sm border border-[#CECECE] bg-[#FAFAFA] rounded-md outline-none text-[#353535]"
          value={card ? card.expiryDate : ''}
          // readOnly
        />
      </div>
      <div className="flex flex-col gap-2">
        <label className="text-[14px]">CVC</label>
        <input
          className="px-3 py-2.5 text-sm border border-[#CECECE] bg-[#FAFAFA] rounded-md outline-none text-[#353535]"
          value={card ? card.cardCvc : ''}
          // readOnly
        />
      </div>
      <button className="bg-[#3da5ec] text-white py-2 rounded-md">
        Confirm
      </button>
    </div>
  );
}

export default CardDetails;
