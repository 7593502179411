import { useState } from "react";
import { FaCheck } from "react-icons/fa";

export default function ScheduleCard({
  discount,
  icon,
  type,
  amount,
  id,
  isSelected,
  onCount,
}) {
  const [count, setCount] = useState(1);
  const [isBlinkingIncrement, setIsBlinkingIncrement] = useState(false);
  const [isBlinkingDecrement, setIsBlinkingDecrement] = useState(false);

  const increment = () => {
    setIsBlinkingIncrement(true);
    let value = count + 1;
    setCount(value);
    onCount(value);
    setTimeout(() => {
      setIsBlinkingIncrement(false);
    }, 150);
  };
  const decrement = () => {
    setIsBlinkingDecrement(true);
    let value = count === 1 ? 1 : count - 1;
    setCount(value);
    onCount(value);
    setTimeout(() => {
      setIsBlinkingDecrement(false);
    }, 150);
  };

  return (
    <div className="h-fit min-w-[300px] max-w-[400px] bg-white border-2 border-blue-400 rounded-lg shadow-md ">
      <div className="flex h-[30px] mb-5  text-white justify-between">
        <div className="text-center bg-primary-sky px-10 py-2 flex justify-center items-center rounded-br-xl">
          <p>{discount}%</p>
        </div>
        <div
          className={`w-[25px] h-[25px] flex items-center justify-center border-2 border-blue-400 rounded-full mr-3 mt-2 cursor-pointer ${
            isSelected ? "bg-blue-400 text-white" : "bg-white"
          }`}
        >
          {isSelected && <FaCheck size={12} />}
        </div>
      </div>
      <div>
        <div className="flex flex-col justify-center items-center mb-5">
          <img src={icon} className="mb-5 mt-5 w-[60px]" alt="icon" />
          <p className="font-medium">{type}</p>
          <p className="font-semibold text-lg">€{count * parseFloat(amount)}</p>
        </div>
        <div className="flex justify-center gap-x-10 mb-8">
          <button
            onClick={increment}
            className={`bg-primary-sky rounded-lg h-9 w-9 text-white transition-all ease-in-out ${
              isBlinkingIncrement ? "opacity-50" : "opacity-100"
            }`}
          >
            +
          </button>
          <p className="mt-2">{count}</p>
          <button
            onClick={decrement}
            className={`bg-primary-sky rounded-lg h-9 w-9 text-white transition-all ease-in-out ${
              isBlinkingDecrement ? "opacity-50" : "opacity-100"
            }`}
          >
            -
          </button>
        </div>
      </div>
    </div>
  );
}
