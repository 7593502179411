import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Introduction from "../dashboard-component/introduction";
import moneyBag from "../../../../assests/images/dashboardHome/money-bag.svg";
import note from "../../../../assests/images/dashboardHome/edit.svg";
import chat from "../../../../assests/images/dashboardHome/chat.svg";
import people from "../../../../assests/images/dashboardReferral/program.svg";
import copy from "../../../../assests/images/dashboardReferral/copy.svg";
import ReferralModal from "./referralModal";
import UpdateCustomerModal from "./accountModal";

export default function Home() {
  const [referralCode, setReferralCode] = useState("");
  const [generateReferralBalance, setGenerateReferralBalance] = useState("");
  const [customer, setCustomer] = useState("");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);

  useEffect(() => {
    const fetchCustomerData = async () => {
      const customerId = sessionStorage.getItem("userId");
      try {
        const response = await axios.get(
          `https://klean-up-server-hz1y.onrender.com/v1/api/getCustomer/${customerId}`
        );
        setReferralCode(response.data.customer.referralCode);
        setGenerateReferralBalance(
          response.data.customer.generateReferralBalance
        );
        setCustomer(response.data.customer);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        toast.error("Failed to fetch customer data");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, []);

  const handleCopyLink = () => {
    const referralLink = `https://klean-up-main.vercel.app/signup?crl=${referralCode}`;
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        toast.success("Referral link copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying referral link:", error);
        toast.error("Failed to copy referral link");
      });
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAccountModalOpen = () => {
    setIsAccountModalOpen(true);
  };

  const handleAccountModalClose = () => {
    setIsAccountModalOpen(false);
  };
  return (
    <div>
      <Introduction />
      <div className="grid grid-cols-3 max-lg:grid-cols-2 max-md:grid-cols-1 mb-20 gap-10 font-helvetica-neue">
        <div className="pt-5 pl-5 bg-[#FEF2DF] border-2 rounded-xl border-[#B66828] overflow-hidden h-[350px] flex flex-col">
          <p className="font-semibold">Referral Bonus</p>
          <p className="text-[14px] text-primary-blurText mt-2">
            Our Refer a Friend program is our way of expressing gratitude for
            your trust and loyalty
          </p>
          <button className="text-white bg-[#B66828] px-3 py-2 mt-5 rounded-full text-[14px] w-fit">
            How it works
          </button>
          <div className="w-full flex mt-auto">
            <p className="mt-auto text-[34px] font-bold">
              {loading ? "Loading..." : `€${generateReferralBalance}`}
            </p>
            <img src={moneyBag} className="ml-auto" />
          </div>
        </div>
        <div className="pt-5 pl-5 bg-[#F2F8FE] border-2 rounded-xl border-[#1667B7] overflow-hidden h-[350px] flex flex-col">
          <p className="font-semibold">Account settings</p>
          <p className="text-[14px] text-primary-blurText max-w-[330px] inline-block mt-2">
            Click the button below to change your account and profile details.
          </p>
          <button
            className="text-white bg-[#1667B7] px-3 py-2 mt-5 rounded-full text-[14px] block w-fit"
            onClick={handleAccountModalOpen}
          >
            Edit Account{" "}
          </button>
          <div className="w-full flex mt-auto">
            <img src={note} className="ml-auto" />
          </div>
        </div>

        <div className="pt-5 pl-5 bg-[#E9FCFB] border-2 rounded-xl border-[#11A499] overflow-hidden flex flex-col h-[350px]">
          <p className="font-semibold">Refer a Friend</p>
          <p className="text-[14px] text-primary-blurText">
            Promo starts at the second successful order
          </p>
          <button
            className="text-white bg-[#B66828] px-3 py-2 mt-5 rounded-full text-[14px] w-fit"
            onClick={handleModalOpen}
          >
            How it works
          </button>
          <div className="w-full flex mt-auto">
            <div className="mt-auto">
              <p className="text-[14px] text-primary-blurText">Referral Link</p>
              <p className="font-medium">
                {loading ? "Loading..." : referralCode}
              </p>
              <div
                className="rounded-full border border-[#11A499] gap-x-1 flex px-2 justify-center items-center bg-white w-fit mb-2 cursor-pointer"
                onClick={handleCopyLink}
              >
                <img src={copy} alt="copy icon" />
                <p className="text-[14px] text-primary-blurText mt-1">
                  copy link
                </p>
              </div>
            </div>
            <img src={people} className="ml-auto" alt="people" />
          </div>
          <ReferralModal isOpen={isModalOpen} onClose={handleModalClose} />
          <UpdateCustomerModal
            isOpen={isAccountModalOpen}
            onClose={handleAccountModalClose}
            customer={customer}
          />
        </div>
      </div>
    </div>
  );
}
