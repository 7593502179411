import RenderBlog from "../renderBlog";

export default function Blog() {
  return (
    <div className="">
      <div className="flex flex-col justify-center items-center font-helvetica-neue pt-10 text-center">
        <p className="font-semibold text-xl mb-5">BLOGS</p>
        <p className="text-3xl font-semibold">
          We are here to help you with your cleaning
        </p>
        <p className="inline-block max-w-[500px] text-sm mt-5 xs:px-4 sm:px-0">
          Tailored to fit your specific needs and schedule, making every move
          stress-free with spotless transitions.
        </p>
      </div>
      <div className=" flex justify-center items-center w-full">
        <RenderBlog />
      </div>
    </div>
  );
}
